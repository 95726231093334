import { CONSTANTS } from "../helpers/variables";
import axiosService from "./axios.service";

class ArticleTypeService {
    async getArticleTypes(keywords, currentPage, perPage, sortBy, sortDesc) {
        let keyword = keywords ? keywords : ""; 
        let articleTypes = await axiosService.get(CONSTANTS.APIURL + `article-types-list?keywords=${keyword}&page=${currentPage}&per_page=${perPage}&column=${sortBy}&orderby=${sortDesc}`);
        return articleTypes;
    }
    async getArticleTypeById(id) {
        let articleTypes = await axiosService.get(CONSTANTS.APIURL + `article-type?id=${id}` );
        return articleTypes;
    }
    async addArticleType(...articleType) {
        return await axiosService.post(CONSTANTS.APIURL + 'article-types', ...articleType);
    }
    async updateArticleType(...articleType) {
        return await axiosService.put(CONSTANTS.APIURL +  'article-types', ...articleType);
    }
    async deleteArticleType(id) {
        return await axiosService.detete(CONSTANTS.APIURL +`article-types?id=${id}`);
    }
    async getArticleType() {
        let articleTypes = await axiosService.get(CONSTANTS.APIURL + `article-type`);
        return articleTypes;
    }
}
export default new ArticleTypeService();